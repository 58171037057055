import { ToggleSwitch } from '../../components/ToggleSwitch'
import { UserOrganizationListCell } from '../../components/UserOrganizationListCell'
import {
  SHOULD_INCLUDE_ADMIN_COLUMN,
  SHOULD_INCLUDE_ORGANIZATION_COLUMN,
  TableOptions,
  TableOptionValues,
} from '../../configs/constants'
import { UserObj } from '../../store/slices/userlist/slice'
import { IconButton, Tooltip } from '@mui/material'
import { StyledChip, TooltipStyles, TooltipText } from './elements'
import InfoIcon from '@mui/icons-material/Info'

export const USERLIST_COLUMNS_CONFIG = [
  {
    [TableOptions.accessorKey]: TableOptionValues.id,
    [TableOptions.header]: 'ID',
  },
  {
    [TableOptions.accessorKey]: TableOptionValues.firstName,
    [TableOptions.header]: 'First Name',
  },
  {
    [TableOptions.accessorKey]: TableOptionValues.lastName,
    [TableOptions.header]: 'Last Name',
  },
  {
    [TableOptions.accessorKey]: TableOptionValues.email,
    [TableOptions.header]: 'Email',
  },
  SHOULD_INCLUDE_ORGANIZATION_COLUMN && {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [TableOptions.cell]: ({ row }: any) => {
      return <UserOrganizationListCell groups={row.original.groups} />
    },
    [TableOptions.header]: 'Organization (s)',
  },
  {
    [TableOptions.cell]: ({ row }: { row: { original: UserObj } }) => {
      return (
        <ToggleSwitch
          user={row.original}
          payload={{ enabled: !row.original.enabled }}
        />
      )
    },
    [TableOptions.header]: 'Enabled',
  },
  SHOULD_INCLUDE_ADMIN_COLUMN && {
    [TableOptions.cell]: ({ row }: { row: { original: UserObj } }) => {
      return (
        <ToggleSwitch
          user={row.original}
          payload={{ admin: !row.original.admin }}
        />
      )
    },
    [TableOptions.header]: (
      <div>
        Admin
        <Tooltip
          placement="top-end"
          arrow
          componentsProps={{
            ...TooltipStyles,
          }}
          title={
            <TooltipText>
              Enabling this option will assign the{' '}
              <StyledChip label="global-admin" variant="outlined" /> role to the
              user&apos;s <StyledChip label="global_role" variant="outlined" />{' '}
              property. If disabled, the{' '}
              <StyledChip label="global_role" variant="outlined" /> will be set
              to <StyledChip label="user" variant="outlined" /> instead.
            </TooltipText>
          }
        >
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </div>
    ),
    [TableOptions.id]: 'Admin',
  },
].filter((v) => v)
