import { styled } from 'styled-components'
import { Chip } from '@mui/material'

export const StyledContentWrapper = styled('div')`
  padding: 35px 20px;
  width: 100%;
`

export const StyledTableHeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  padding: 0 20px 15px;
`

export const StyledTableBodyWrapper = styled('div')`
  padding: 10px;
`

export const StyledTableTitle = styled('div')`
  font-size: 2rem;
  flex-grow: 1;
`

export const StyledTableFooterWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px 15px 20px;
`

export const TooltipStyles = {
  tooltip: {
    sx: {
      maxWidth: '500px',
      lineHeight: '32px',
    },
  },
}

export const TooltipText = styled('div')`
  font-size: 16px;
  && .MuiChip-root {
    border-color: white;
    height: 22px;
  }
`

export const StyledChip = styled(Chip)`
  && .MuiChip-label {
    font-size: 16px;
  }
`
